// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-postlayout-js": () => import("/opt/build/repo/src/components/postlayout.js" /* webpackChunkName: "component---src-components-postlayout-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-danbook-js": () => import("/opt/build/repo/src/pages/danbook.js" /* webpackChunkName: "component---src-pages-danbook-js" */),
  "component---src-pages-dandeck-js": () => import("/opt/build/repo/src/pages/dandeck.js" /* webpackChunkName: "component---src-pages-dandeck-js" */),
  "component---src-pages-danstagram-js": () => import("/opt/build/repo/src/pages/danstagram.js" /* webpackChunkName: "component---src-pages-danstagram-js" */),
  "component---src-pages-danvideo-js": () => import("/opt/build/repo/src/pages/danvideo.js" /* webpackChunkName: "component---src-pages-danvideo-js" */),
  "component---src-pages-dnd-combat-js": () => import("/opt/build/repo/src/pages/dnd_combat.js" /* webpackChunkName: "component---src-pages-dnd-combat-js" */),
  "component---src-pages-dnd-inventory-js": () => import("/opt/build/repo/src/pages/dnd_inventory.js" /* webpackChunkName: "component---src-pages-dnd-inventory-js" */),
  "component---src-pages-dnd-stats-js": () => import("/opt/build/repo/src/pages/dnd_stats.js" /* webpackChunkName: "component---src-pages-dnd-stats-js" */),
  "component---src-pages-dnd-js": () => import("/opt/build/repo/src/pages/dnd.js" /* webpackChunkName: "component---src-pages-dnd-js" */),
  "component---src-pages-flexbox-js": () => import("/opt/build/repo/src/pages/flexbox.js" /* webpackChunkName: "component---src-pages-flexbox-js" */),
  "component---src-pages-grid-js": () => import("/opt/build/repo/src/pages/grid.js" /* webpackChunkName: "component---src-pages-grid-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

